'use strict';

let containerDiv = document.getElementById('custom-ccp');

document.getElementById('versionCode').innerHTML = VERSION || "1.0.0";

const ccpUrl = CCP_URL? CCP_URL : '';
const loginUrl = CCP_LOGIN_URL? CCP_LOGIN_URL : '';
const region = CCP_REGION ? CCP_REGION : 'eu-central-1';

const checkoutOffline = 3600000; // 1h
let mContact = null;
let mAgent = null;
let contactQueue = null;
let contactLanguage = null;
let isCallback = null;
let cp = null;
let cleansingTimeout = null;

const DEFAULT_OFFLINE_UTC_HOUR = 18; // Official closing time is 14 o'clock (EU/Mad TZ), but giving some more time to end evening work
const ERROR_MANUAL_STATE_CHANGE_TO_OFFLINE = 'El Servei del SOC ha tancat l\'horari d\'oficina, siusplau posi el seu estat a Desconnectat/Offline.';
const ERROR_AUTH_FAIL = 'El procés d\'autenticació ha fallat. Siusplau recarregui la pàgina i torni a introduir les seves dades.';

// initialize the streams api
function init() {
  try {
    // initialize the ccp
    connect.core.initCCP(containerDiv, {
      ccpUrl,
      loginPopupAutoClose: true,
      loginPopup: true,
      loginUrl,
      loginOptions: {
        autoClose: true,
        width: 900,
        height: 600,
      },
      region,
      pageOptions: {
        enableAudioDeviceSettings: true,
      },
      softphone: {
        allowFramedSoftphone: true,
      }
    });
  
    connect.core.onInitialized(() => {
      // console.log('*** CCP fully initialized!', AWS);
      try {
        document.getElementById('custom-ccp').classList.remove('hidden');
        document.getElementById('custom-ccp-loading').classList.add('hidden');
      } catch(err) {
        console.log('Error initializing CCP', err)
      }
    });

    connect.core.onAuthFail(() => {
      console.error('*** Auth failed ');
      window.alert(ERROR_AUTH_FAIL);
    });

    // Disconnect agent after closing window
    let mAgent;
  
    connect.agent(function(agent) {
      mAgent = agent;
    });
  
    window.addEventListener("beforeunload", () => {
      setAgentStatusOffline(mAgent);
      document.getElementById('custom-ccp-loading').classList.remove('hidden');
    });

    window.setInterval(() => {
      const date = new Date();
      if (date.getUTCHours() == DEFAULT_OFFLINE_UTC_HOUR) {
        console.log('--- Set offline')
        setAgentStatusOffline(mAgent);
      }
    }, checkoutOffline); // Repeat every hour
   

    loadCategories();
    // Load categories
  } catch (error) {
    console.log(error);
  }

  connect.contact(subscribeToContactEvents);
}

function loadCategories() {
  const apigClient = apigClientFactory.newClient();

  apigClient.classificationOptionsOptions({},{},{})
  .then(function({data}) {
    if (!data || !data.Items || (data.Items.length < 1)) return;

    const select = document.getElementById('call-classifier-select');
    // Delete old categories
    while (select.options.length > 0) {
      select.remove(select.options[length-1]);
    }

    data.Items.sort((a,b) => { return a.value > b.value ? 1: -1; });
    
    data.Items.forEach(item => {
      const opt = document.createElement('option');
      opt.text = item.value;
      opt.value = item.id;
      opt.title = item.description;
      select.add(opt, null);
    });

  }).catch( function(error) {
    console.log('Error requesting categories', error);
  });
}


function setAgentStatusOffline(mAgent) {
  if (mAgent != null) {
    let states = mAgent.getAgentStates();
    // console.log('states', states);
    let offlineState = states.filter(state => state.name === "Offline")[0];
    
    // Change agent state
    mAgent.setState(offlineState, {
      success: function() {
        console.log("Agent change state succeeded");
      },
      failure: function() {
        console.log("Agent change State failed");
      }
    });
  }
}

function subscribeToContactEvents(contact) {
  contact.onIncoming(handleContactConnected);
  contact.onAccepted(handleContactConnected);

  contact.onConnected(handleContactConnected);
  contact.onError(handleError);
  contact.onDestroy(handleContactDestroyed);
}

function handleContactConnected(contact) {
  if (contact) {
    try {
      clearTimeout(cleansingTimeout);
      // console.log(`Current contact:  ${mContact.getContactId()} ;;;; but previous queue ${contactQueue}`)
    } catch (error) { console.log('No need to clear a timeout') };
    clearLastCallData();

    mContact = contact;

    // document.getElementById('call-classifier').classList.remove('hidden');
    document.getElementById('call-classifier-submit').disabled = false;
    // document.getElementById('call-info').classList.remove('hidden');

    fillCallInfoForm(contact);
  }
}

function clearLastCallData() {
  mContact = null;
  contactLanguage = '';
  contactQueue = '';
  isCallback = '';
  cp = '';

  document.getElementById('call-classifier-select').getElementsByTagName('option')[0].selected = 'selected';
  document.getElementById('call-classifier-submit').disabled = true;  
}

function handleContactDestroyed(contact) {
  document.getElementById('attr-idioma').innerHTML = '';
  document.getElementById('attr-cp').innerHTML = '';
  document.getElementById('attr-cua').innerHTML = '';

  // Give the agents some more time to answer call classification
  cleansingTimeout = setTimeout(clearLastCallData, 15000);
}

function handleError(error) {
  console.error('There has been an error', error);
  // document.getElementById('call-classifier').classList.add('hidden');
  mContact = null;
}

function fillCallInfoForm(contact) {
  const contactAttributes = contact.getAttributes();
  // console.log('*** contact attrs', contactAttributes);
  if (contactAttributes.CI_Seleccion && contactAttributes.CI_Seleccion.value && contactAttributes.CI_Seleccion.value == 1) 
    document.getElementById('attr-idioma').innerHTML = 'Català';
  else if (contactAttributes.CI_Seleccion && contactAttributes.CI_Seleccion.value && contactAttributes.CI_Seleccion.value == 2) 
    document.getElementById('attr-idioma').innerHTML = 'Castellano';
  else
    document.getElementById('attr-idioma').innerHTML = 'No disponible';
  
  contactLanguage = document.getElementById('attr-idioma').innerHTML;
  
  // console.log('********************* CONTACT ATTRS', JSON.stringify(contactAttributes));
  // document.getElementById('attr-cua').innerHTML = contactAttributes.CI_Queue && contactAttributes.CI_Queue.value || 'No disponible';
  // contactQueue = contactAttributes.CI_Queue && contactAttributes.CI_Queue.value || 'No disponible';

  const queue = contact.getQueue();
  contactQueue =  queue && queue.name || contactAttributes.CI_Queue && contactAttributes.CI_Queue.value || 'No disponible';
  document.getElementById('attr-cua').innerHTML = contactQueue;

  cp = contactAttributes.CI_CodiPostal && contactAttributes.CI_CodiPostal.value || 'No disponible';
  document.getElementById('attr-cp').innerHTML = cp;

  isCallback = !contact.isInbound();
}

// Store contact classification​
const callClassifierForm = document.getElementById('call-classifier-form');
callClassifierForm.addEventListener('submit', async function (e) {
  e.preventDefault();
  document.getElementById('call-classifier-submit').disabled = true;

  const apigClient = apigClientFactory.newClient();

  const formDataRaw = new FormData(callClassifierForm).entries();
  const formData = Object.fromEntries(formDataRaw);
  formData.userId = getUserId(),
  formData.date = getDate();
  formData.queue = contactQueue;
  formData.language = contactLanguage;
  formData.isCallback = isCallback.toString();
  formData.cp = cp;

  if (!mContact || mContact === null) {
    console.log('No contact');
    return;
  }
  
  apigClient.classificationValuesOptions({}, formData, {})
    .then(function() {
      // Notify users
      Toastify({
        text: "Informació enviada correctament!",
        duration: 5000,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
          color: "#333",
          background: "linear-gradient(to bottom, #FFF, #96c93d)",
        },
      }).showToast();
    }).catch( function(error) {
      console.log('Error sending request to API', error);
    });
  
});

// format 2020-06-05T08:57:19Z
function getDate() {
  const now  = new Date();
  return now.toISOString();
}

// User id is a combination of id and timestamp
function getUserId() {
  const user = mContact && mContact.getContactId() || 'unknown';
  const now = new Date();
  return `${user}#${now.getTime()}`;
}